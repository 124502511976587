@import url('@volvo-cars/css/breakpoints.css');

.collect-hero-text {
  flex: 1;
  flex-basis: 50%;
  order: 2;
}

.collect-hero-image {
  flex: 1;
  flex-basis: 50%;
  order: 1;
}

@media (--v-from-md) {
  .collect-hero-text {
    order: 1;
  }

  .collect-hero-image {
    order: 2;
  }
}

@media (--v-from-lg) {
  .collect-hero-text-content {
    width: 70%;
  }
}
