@import url('@volvo-cars/css/breakpoints.css');

@media (--v-from-lg) {
  .cart-layout-view {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .cart-layout-header {
    width: 652px;
  }
}

@media (--v-until-sm) {
  .cart-layout-header {
    width: 327px;
  }
}
