@import url('@volvo-cars/css/breakpoints.css');

.product-details-layout-section {
  grid-template-columns: 1fr;
  gap: 32px;
}

.product-details-body-content-mb {
  margin-bottom: 96px;
}

.product-details-body-content-w-full {
  position: relative;
  width: 100vw;
  inset-inline-start: calc(-50vw + 50%);
}

.product-details-layout-content-bg-counter {
  padding: 24px;
}

@media (--v-until-md) {
  .product-details-body-content-mb {
    margin-bottom: 64px;
  }

  .product-details-layout-content-bg-counter {
    margin: 0px -24px;
  }
}

@media (--v-from-md) and (--v-until-lg) {
  .product-details-header-content {
    padding: 0% 8.3%;
  }

  /* Countering header-content & body-content */
  .product-details-layout-content-bg-counter {
    padding-inline: 16.6%;
    margin: 0% -16.6%;
  }
}

@media (--v-until-lg) {
  /* Countering header-content & body-content */
  .product-details-layout-media {
    margin: 0px -32px;
  }
}

@media (--v-from-lg) {
  .product-details-layout-section {
    grid-template-columns: 2fr 1fr;
    gap: 8.3%;
  }

  .product-details-body-content {
    padding: 0% 8.3%;
  }
}
