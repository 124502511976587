@media (--v-until-md) {
  .gradient-light {
    background: linear-gradient(
      var(--gradient-direction, 180deg),
      rgb(255 255 255 / 45%) 0%,
      rgb(255 255 255 / 0%) 100%
    );
  }
  .gradient-dark {
    background: linear-gradient(
      var(--gradient-direction, 180deg),
      rgb(0 0 0 / 45%) 0%,
      rgb(0 0 0 / 0%) 100%
    );
  }
}

@media (--v-from-md) {
  .md\:gradient-light {
    background: linear-gradient(
      var(--gradient-direction, 180deg),
      rgb(255 255 255 / 45%) 0%,
      rgb(255 255 255 / 0%) 100%
    );
  }
  .md\:gradient-dark {
    background: linear-gradient(
      var(--gradient-direction, 180deg),
      rgb(0 0 0 / 45%) 0%,
      rgb(0 0 0 / 0%) 100%
    );
  }
}

@media (--v-from-md) {
  .md\:gradient-left {
    --gradient-direction: 90deg;
    [dir='rtl'] & {
      --gradient-direction: 270deg;
    }
  }

  .md\:gradient-right {
    --gradient-direction: 270deg;
    [dir='rtl'] & {
      --gradient-direction: 90deg;
    }
  }
}

.pointer-none {
  pointer-events: none;
}

.pointer-auto {
  pointer-events: auto;
}

@media (--v-until-md) {
  .video-toggle-cta {
    top: var(--v-space-8);
  }
}
@media (--v-from-md) {
  .video-toggle-cta {
    bottom: var(--v-space-24);
  }
}
