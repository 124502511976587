@import url('@volvo-cars/css/breakpoints.css');

@media (--v-until-md) {
  .product-row-card-3 a {
    flex-basis: calc(50% - 0.75rem);
  }
  .product-row-card-3 a:nth-child(5) {
    flex-basis: 100%;
  }
}

@media (--v-from-md) {
  .product-row-card-3 a {
    flex-basis: calc(33.3% - 0.75rem);
  }
}

.product-row-card-2 a {
  flex-basis: calc(50% - 0.75rem);
}

.product-row-card-1 a {
  flex-basis: 100%;
}
