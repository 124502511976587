@import url('@volvo-cars/css/breakpoints.css');

@media (--v-until-lg) {
  .campaign-slot-entry-side-padding {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
  }
}

@media (--v-from-lg) {
  .campaign-slot-entry-side-padding {
    padding-inline-start: 132px;
    padding-inline-end: 132px;
  }
}
