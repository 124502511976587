@import url('@volvo-cars/css/breakpoints.css');

@media (--v-from-lg) {
  .payment-summary-header {
    width: 540px;
  }

  .payment-summary-details {
    max-width: 540px;
  }
}
