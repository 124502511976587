.service-plan-info-feature {
  list-style-type: none;
}

@keyframes fade-animation {
  from {
    opacity: 0.32;
  }
  to {
    opacity: 1;
  }
}

.service-plan-opaque {
  opacity: 0.32;
}

.service-plan-info-container {
  flex-basis: 33%;
  transition-duration: var(--_v57fab3);
  animation-name: fade-animation;
}

@keyframes unfade-animation {
  from {
    opacity: 0.32;
  }
  to {
    opacity: 1;
  }
}
.service-plan-info-container-faded:hover {
  opacity: 1;
  transition-duration: var(--_v57fab3);
  animation-name: unfade-animation;
}
