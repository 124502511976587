.calloutCardImageContainer {
  max-width: 72px;
  max-height: 72px;
}

/*
  Padding added to maintain same size as Google Play badge.
  If any changes occur to badges, adjust this accordingly
*/
.appStoreBadge {
  padding: 10px 0px;
}

.googlePlayBadge {
  margin-inline-end: -11px;
}
