@import url('@volvo-cars/css/breakpoints.css');

@media (--v-from-lg) {
  .order-summary-layout {
    min-width: 546px;
    max-width: 588px;
  }
}

/* Reducing the width so the gap can remain the same without interfering with the layout */
@media (min-width: 1024px) and (max-width: 1166px) {
  .order-summary-layout {
    min-width: 446px;
  }
}
