@import url('@volvo-cars/css/breakpoints.css');

.product-card-link img {
  scale: 1;
  transition: scale;
  transition-duration: 200ms;
}

.product-card-link:hover img,
.product-card-link:focus img {
  scale: 1.1;
}

@media (--v-until-md) {
  .product-card-img-sm-full {
    width: 100%;
    height: 100%;
  }

  .product-card-img-container-sm-alternate-aspect-ratio {
    aspect-ratio: 3 / 4;
  }
}

@media (--v-from-lg) {
  .product-card-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--v-from-md) and (--v-until-lg) {
  .product-card-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
