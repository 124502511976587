@import url('@volvo-cars/css/breakpoints.css');

.carousel-card {
  width: calc(33.3% - 0.7rem);
}

@media (--v-until-lg) {
  .carousel-card {
    width: fit-content;
  }
}
