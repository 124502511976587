.sources-info-wrapper {
  inset-inline-start: 8px;
  z-index: var(--sitenav-z-index-max);
}

.sources-info-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: width 0.5s ease, opacity 0.5s ease;
}

.sources-info-item-full {
  width: 100%;
  opacity: 1;
}

.sources-info-item-empty {
  width: 0px;
  opacity: 0;
}
